import React from 'react'
import Helmet from 'react-helmet'
import g from 'glamorous'
import { css } from 'glamor'
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button'
import 'normalize.css'
import './styles.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Layout = ({ children }) => (
  <Div>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <Header />

    <Main>{children}</Main>

    <ScrollUpButton
      ShowAtPosition={10}
      style={{
        fill: 'rgb(0,0,0, .35)',
        backgroundColor: `rgb(255, 255, 255, .6)`,
      }}
    />
  </Div>
)

export default Layout

const Div = g.div({
  maxWidth: `1075px`,
  margin: `0 auto`,
  paddingBottom: `6em`,
})

const Main = g.main({
  margin: '20px auto 1em',
  padding: '0',
  '@media(max-width: 768px)': {
    margin: '10px auto 1em',
    padding: '0',
  },
})
