import React from 'react'
import { Link } from 'gatsby'
import g from 'glamorous'
import { css } from 'glamor'

const Footer = () => (
  <FooterWrapper>
    <div>
      <Link {...cssLink} to="/copyright">
        Copyright
      </Link>
      <Link {...cssLink} to="/legal-information/">
        Legal Notice
      </Link>
    </div>
  </FooterWrapper>
)

export default Footer

const FooterWrapper = g.footer({
  padding: `2em 0 2em`,
  textAlign: `center`,
})

const cssLink = css({
  color: 'rgba(31,31,31,.6)',
  fontSize: `.8em`,
  textDecoration: `none`,
  borderBottom: `none`,
  transition: `all .3s`,
  padding: `0 0 3px 0`,
  margin: `0 .75em`,
  position: `relative`,
  '::before': {
    content: ' ',
    position: `absolute`,
    width: `100%`,
    height: `1px`,
    bottom: `0`,
    left: `0`,
    backgroundColor: `rgba(31,31,31,.6)`,
    visibility: `invisible`,
    transform: `scaleX(0)`,
    transition: `all 0.3s ease-in-out 0s`,
  },
  ':hover': {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 3px 0`,

    '::before': {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  },
})
