import React from 'react'
import { Link } from 'gatsby'
import g from 'glamorous'
import { css } from 'glamor'
import Logo from './Logo-mobile'
import { FaInstagram } from 'react-icons/fa'

const bounce = css.keyframes({
  '0%': { transform: 'translateX(-100%)', opacity: 0.3 },
  '20%': { opacity: 0.5 },
  '100%': { transform: 'translateX(0)', opacity: 1 },
})

const mediaQueries = {
  phone: '@media only screen and (max-width: 991px)',
}

const Wrapper = g.div({
  backgroundColor: `white`,
  display: `none`,
  '@media only screen and (max-width : 768px)': {
    display: `block`,
  },
  position: `relative`,
  paddingBottom: `60px`,
})

const Burger = g.button({
  display: `none`,
  marginRight: `auto`,
  zIndex: `5`,
  padding: `38px 16px 15px`,
  cursor: `pointer`,
  transitionProperty: `opacity, filter`,
  transitionDuration: `0.15s`,
  transitionTimingFunction: `linear`,
  font: `inherit`,
  color: `inherit`,
  textTransform: `none`,
  backgroundColor: `transparent`,
  border: `0`,
  margin: `0`,
  overflow: `visible`,
  top: `auto`,
  bottom: `10`,
  outline: `none`,
  [mediaQueries.phone]: {
    display: `flex`,
    height: `100%`,
    alignItems: `center`,
  },
  '.toggled &': { marginRight: `auto` },
})

const HamburgerBox = g.span({
  width: `40px`,
  height: `24px`,
  display: `inlineBlock`,
  position: `relative`,
})

const HamburgerInner = g.span({
  display: `block`,
  top: `50%`,
  marginTop: `0`,
  width: `30px`,
  height: `2px`,
  backgroundColor: `#333`,
  borderRadius: `4px`,
  position: `absolute`,
  transitionDuration: `0.13s`,
  transitionDelay: `0.13s`,
  transitionTimingFunction: `cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  '.toggled &': {
    transform: `translate3d(0, -10px, 0) rotate(-45deg)`,
    transitionDelay: `0.22s`,
    transitionTimingFunction: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
  },
  '&::before': {
    width: `30px`,
    height: `2px`,
    backgroundColor: `#333`,
    borderRadius: `4px`,
    position: `absolute`,
    transitionProperty: `transform`,
    transitionDuration: `0.15s`,
    transitionTimingFunction: `ease`,
    content: `""`,
    display: `block`,
    top: `-10px`,
    transition: `top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
    '.toggled &': {
      top: `0`,
      transform: `rotate(-90deg)`,
      transition: `top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1)`,
    },
  },
  '&::after': {
    width: `30px`,
    height: `2px`,
    backgroundColor: `#333`,
    borderRadius: `4px`,
    position: `absolute`,
    transitionProperty: `transform`,
    transitionDuration: `0.15s`,
    transitionTimingFunction: `ease`,
    content: `""`,
    display: `block`,
    top: `-20px`,
    transition: `top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear`,
    '.toggled &': {
      top: `0`,
      opacity: `0`,
      transition: `top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear`,
    },
  },
})

const LogoWrapper = g.div({
  position: `absolute`,
  width: `100%`,
  textAlign: `center`,
  top: 0,
  paddingTop: `8px`,
})

const Nav = g.div({
  animation: `${bounce} .3s ease-out`,
  width: `100%`,
  height: `95vh`,
  position: `absolute`,
  top: `75px`,
  left: `-800px`,
  display: `flex`,
  flexDirection: `column`,
  backgroundColor: `white`,
  transition: `all 0.5s`,
  paddingTop: `1.2em`,
  '.toggled &': {
    left: `0`,
  },
})

const NavItem = g.div({
  marginLeft: `25px`,
  marginBottom: `20px`,
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: `0`,
  paddingRight: 0,
  fontWeight: `500`,
  fontSize: `18px`,
  letterSpacing: `.04em`,
})

const cssLink = css({
  paddingBottom: `3px`,
  color: `#333`,
  textDecoration: `none`,
})

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: 'item link-active' } : null

css.global('.link-active', { borderBottom: '.09em solid #333' })

export default class NavMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = { toggled: false }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    const currentState = this.state.toggled
    this.setState({ toggled: !currentState })
  }

  render() {
    return (
      <Wrapper className={this.state.toggled ? 'toggled' : 'desktop'}>
        <LogoWrapper>
          <Logo width="125px" />
        </LogoWrapper>
        <Burger onClick={this.toggleNav} aria-label="Ouvrir menu de navigation">
          <HamburgerBox>
            <HamburgerInner />
          </HamburgerBox>
        </Burger>

        <Nav className={this.state.toggled ? 'toggled' : 'desktop'}>
          <NavItem>
            <Link to="/" {...cssLink} activeClassName="link-active">
              Home
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about/" {...cssLink} activeClassName="link-active">
              About
            </Link>
          </NavItem>
          <NavItem>
            <Link
              to="/projects/"
              {...cssLink}
              activeClassName={Link === '/' ? 'link-active' : undefined}
              getProps={Link === '/' ? undefined : isPartiallyActive}
            >
              Projects
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/press/" {...cssLink} activeClassName="link-active">
              Press
            </Link>
          </NavItem>

          <NavItem>
            <Link to="/services/" {...cssLink} activeClassName="link-active">
              Services
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/clients/" {...cssLink} activeClassName="link-active">
              Clients
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/contact/" {...cssLink} activeClassName="link-active">
              Contact
            </Link>
          </NavItem>
          <NavItem>
            <a
              href="https://www.instagram.com/valeriepasquiou/"
              {...cssLink}
              target="_blank"
            >
              <FaInstagram size={20} />
            </a>
          </NavItem>
        </Nav>
      </Wrapper>
    )
  }
}
