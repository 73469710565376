import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import g from 'glamorous'
import Img from 'gatsby-image'

export default props => (
  <StaticQuery
    query={graphql`
      query logoMobileQuery {
        file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Valerie Pasquiou interiors"
        />
      </Wrapper>
    )}
  />
)

const Wrapper = g.div({
  width: `220px`,
  margin: `0 auto`,
})
