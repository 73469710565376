import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import g from 'glamorous'
import { css } from 'glamor'
import { IoIosDownload } from 'react-icons/io'
import { Helmet } from 'react-helmet'

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>
          {post.frontmatter.title} - {post.frontmatter.date} | Valerie Pasquiou
          Interiors & Design
        </title>
        <meta
          name="description"
          content={`Article about one of our project in ${
            post.frontmatter.title
          }, ${post.frontmatter.date}, ${post.frontmatter.country}.`}
        />
      </Helmet>
      <Wrapper>
        <Col>
          <Fixter>
            <H1>{post.frontmatter.title}</H1>
            <Infos>{post.frontmatter.date}</Infos>
            <Infos>{post.frontmatter.country}</Infos>
          </Fixter>
        </Col>
        <Col>
          <Div dangerouslySetInnerHTML={{ __html: post.html }} />

          <a
            href={post.frontmatter.pdf}
            target="_blank"
            rel="noopener noreferrer"
            {...cssWhiteLink}
          >
            <IoIosDownload {...cssIcon} />
            Download
          </a>
        </Col>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        country
        date
        pdf
      }
      html
    }
  }
`

const Wrapper = g.div({
  marginTop: `60px`,
  display: `grid`,
  gridTemplateColumns: `33% 67% `,
  '@media(max-width: 768px)': {
    marginTop: `0`,
    display: `block`,
    padding: `0 20px`,
  },
})

const Col = g.div({
  width: `100%`,
})

const Fixter = g.div({
  position: `fixed`,
  maxWidth: `33%`,
  '@media(max-width: 768px)': {
    position: `inherit`,
    width: `100%`,
    maxWidth: `100%`,
    marginBottom: `20px`,
  },
})

const Div = g.div({
  '& .gatsby-resp-image-wrapper': {
    marginBottom: `70px`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.04)`,
    '@media(max-width: 768px)': {
      marginBottom: `40px`,
    },
  },
})

const H1 = g.h1({
  fontSize: `3em`,
  lineHeight: `1em`,
  marginBottom: `0.1em`,
  '@media(max-width: 768px)': {
    marginTop: `0`,
    fontSize: `2.2em`,
  },
})

const Infos = g.div({
  color: `rgba(31,31,31,.5)`,
  fontSize: `.9em`,
  letterSpacing: `.04em`,
  marginLeft: `2px`,
})

const cssIcon = css({
  fontSize: `1.15em`,
  marginRight: `5px`,
  paddingTop: `3px`,
})

const cssWhiteLink = css({
  float: `right`,
  textDecoration: `none`,
  textAlign: `center`,
  padding: `.3em 1em .35em`,
  display: `inline-block`,
  fontSize: `1.1em`,

  borderRadius: `3px`,
  border: `1px solid grey`,
  backgroundColor: `white`,
  color: `black`,
  ':focus': {
    outline: `none`,
  },
  ':hover': {
    border: `1px solid rgba(73,105,126,0.5)`,
    boxShadow: `0 1px 8px rgba(0,0,0,.26)`,
    backgroundColor: `black`,
    color: `white`,
    transition: `all 0.1s ease-in-out`,
    outline: `none`,
  },
})
