import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default props => (
  <StaticQuery
    query={graphql`
      query logoQuery {
        file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Valerie Pasquiou interiors "
        width="100%"
      />
    )}
  />
)
