import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import Logo from './Logo'
import NavMobile from './NavMobile'
import g from 'glamorous'
import { css } from 'glamor'
import { FaInstagram } from 'react-icons/fa'

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { className: 'item active' } : null

const Header = () => (
  <Nav>
    <Headroom>
      <NavDesktop>
        <NavItem css={{ gridColumn: `1` }} {...cssNavItem} {...cssNavItemLeft}>
          <Link to="/about/" {...cssLink} activeClassName="active">
            About
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `2` }} {...cssNavItem} {...cssNavItemLeft}>
          <Link
            to="/projects/"
            {...cssLink}
            activeClassName={Link === '/' ? 'active' : undefined}
            getProps={Link === '/' ? undefined : isPartiallyActive}
          >
            Projects
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `3` }} {...cssNavItem} {...cssNavItemLeft}>
          <Link
            to="/press/"
            {...cssLink}
            activeClassName={Link === '/' ? 'active' : undefined}
            getProps={Link === '/' ? undefined : isPartiallyActive}
          >
            Press
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `4`, margin: `0px`, width: `270px` }}>
          <Link to="/">
            <Logo width="" />
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `5` }} {...cssNavItem} {...cssNavItemRight}>
          <Link to="/services/" {...cssLink} activeClassName="active">
            Services
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `6` }} {...cssNavItem} {...cssNavItemRight}>
          <Link to="/clients/" {...cssLink} activeClassName="active">
            Clients
          </Link>
        </NavItem>

        <NavItem css={{ gridColumn: `7` }} {...cssNavItem} {...cssNavItemRight}>
          <Link to="/contact/" {...cssLink} activeClassName="active">
            Contact
          </Link>
        </NavItem>
        <InstaWrapper>
          <a
            href="https://www.instagram.com/valeriepasquiou/"
            target="_blank"
            rel="noopener noreferrer"
            {...cssInstaLink}
          >
            <FaInstagram size={20} />
          </a>
        </InstaWrapper>
      </NavDesktop>
      <NavMobile />
    </Headroom>
  </Nav>
)

export default Header

const Nav = g.nav({
  height: `80px`,
})

const InstaWrapper = g.div({
  position: `absolute`,
  paddingTop: `10px`,
  top: `0`,
  right: `0`,
})

const NavDesktop = g.div({
  margin: `0 auto`,
  padding: `.5em 0em .75em`,
  display: `grid`,
  gridTemplateColumns: `repeat(7, 1fr)`,
  gridAutoRows: `auto`,
  gridGap: `1em`,
  alignItems: `center`,
  textAlign: `center`,
  maxWidth: `1075px`,
  backgroundColor: `white`,
  position: `relative`,
  '@media only screen and (max-width : 768px)': {
    display: `none`,
  },
})

const NavItem = g.div({
  fontFamily: `Gill Sans`,
  textTransform: `uppercase`,
  fontSize: `.9em`,
  letterSpacing: `.2em`,
  fontWeight: `bold`,
})

const cssNavItem = css({
  paddingTop: `70px`,
})

const cssNavItemLeft = css({
  textAlign: `left`,
})

const cssNavItemRight = css({
  textAlign: `right`,
})

const cssLink = css({
  color: `#1d1d1b !important`,
  letterSpacing: `0.08em`,
  fontSize: `.94em`,
  fontWeight: `400`,
  textDecoration: `none`,
  transition: `all .3s`,
  padding: `0 0 5px 0`,
  margin: `0`,
  position: `relative`,
  '::before': {
    content: ' ',
    position: `absolute`,
    width: `100%`,
    height: `1px`,
    bottom: `0`,
    left: `0`,
    backgroundColor: `#333`,
    visibility: `invisible`,
    transform: `scaleX(0)`,
    transition: `all 0.3s ease-in-out 0s`,
  },
  ':hover': {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 5px 0`,
    margin: `0`,
    opacity: `1`,
    '::before': {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  },
})

const cssInstaLink = css({
  color: `#333`,
  ':hover': {
    opacity: `.7`,
  },
})
